.App-logo {
  height: 80px;
  margin-bottom: 40px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

@import url('https://fonts.googleapis.com/css?family=Martel');

.App-header {
  background-color: var(--main-bg-color);
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Martel";
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bp3-navbar {
  font-family: "Martel";
  align-items: stretch;
  flex: 1 1;
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: center;
}

.footer-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-logo {
  margin-left: 10px;
  margin-right: 10px;
  width: 76px;
}

.footer-content {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.footer-content > div {
  flex: 1 1 0;
}

.bp3-navbar-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.app-body {
  --main-bg-color: #283a4d;
  text-align: center;
  font-family: "neue-haas-grotesk-display";
  max-width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.app-footer {
  align-items: center;
  background-color: var(--main-bg-color);
  flex: none;
  height: 60px;
  display: flex;
  flex-direction: row;
  color: white;
}
